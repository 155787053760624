const BANNERS = [
    {
        slug: {
            tr: '/wholesale-clothing',
            en: '/wholesale-clothing',
        },
        photo: {
            web: 'https://cloud.keikei.com/cdn/fervente/banner/frv-banner-desktop1.jpg',
            mobile: 'https://cloud.keikei.com/cdn/fervente/banner/frv-banner-mobile1.jpg',
        },
    },
    {
        slug: {
            tr: '/plus-size-dress',
            en: '/plus-size-dress',
        },
        photo: {
            web: 'https://cloud.keikei.com/cdn/fervente/banner/frv-banner-desktop2.jpg',
            mobile: 'https://cloud.keikei.com/cdn/fervente/banner/frv-banner-mobile2.jpg',
        },
    },
    {
        slug: {
            tr: '/production-request',
            en: '/production-request',
        },
        photo: {
            web: 'https://cloud.keikei.com/cdn/fervente/banner/frv-banner-desktop3.jpg',
            mobile: 'https://cloud.keikei.com/cdn/fervente/banner/frv-banner-mobile3.jpg',
        },
    },
];

export default BANNERS;

export const firstBanner = {
    slug: '/wholesale-clothing',
    photo: 'https://cloud.keikei.com/cdn/fervente/banner/1.webp?kkk',
    mobilePhoto: 'https://cloud.keikei.com/cdn/fervente/banner/mobile/1.webp?kkk',
};
export const secondBanner = {
    slug: '/dress',
    photo: 'https://cloud.keikei.com/cdn/fervente/banner/2.webp?kkk',
    mobilePhoto: 'https://cloud.keikei.com/cdn/fervente/banner/mobile/2.webp?kkk',
};

export const thirdBanner = {
    slug: '/new',
    photo: 'https://cloud.keikei.com/cdn/fervente/banner/3.webp?kkk',
    mobilePhoto: 'https://cloud.keikei.com/cdn/fervente/banner/mobile/3.webp?kkk',
};

export const fourthBanner = {
    slug: '/production-request',
    photo: 'https://cloud.keikei.com/cdn/fervente/banner/4.webp?kkk',
    mobilePhoto: 'https://cloud.keikei.com/cdn/fervente/banner/mobile/4.webp?kkk',
};
